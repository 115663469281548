'use strict';

angular.module('windmanagerApp', [
  'windmanagerApp.auth',
  'windmanagerApp.admin',
  'windmanagerApp.constants',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'btford.socket-io',
  'ui.router',
  'ui.bootstrap',
  'validation.match',
  'angularSpinner',
  'ngMap',
  'angularMoment',
  'uiSwitch',
  'nvd3',
  'toastr',
  'angular-spinkit',
  'ngFileUpload',
  'ui.select',
  'isteven-multi-select',
  'daterangepicker',
  'dndLists',
  'ngWYSIWYG',
  'wipImageZoom',

])
  .config(function($urlRouterProvider, $locationProvider, usSpinnerConfigProvider, wipImageZoomConfigProvider) {
    wipImageZoomConfigProvider.setDefaults({
      style: 'box'
    });
    $urlRouterProvider
      .otherwise('/');

    $locationProvider.html5Mode(true);
    usSpinnerConfigProvider.setDefaults({color: '#67d3e0'});
  })
  .run(function ($rootScope, $location, Auth) {
    // Redirect to login if route requires auth and you're not logged in
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
      Auth.isLoggedInAsync(function (loggedIn) {
        if (toState.authenticate && !loggedIn) {
          $rootScope.returnToState = $location.path();
          // $rootScope.returnToStateParams = toParams.Id;
          $location.path('/login');
        }
      });
    });
  });
